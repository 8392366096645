<template>
  <div class="adhocCharges">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading">
        <v-form
          class="ma-2"
          v-model="valid"
          @submit.prevent
          v-if="!bulkUpload || runQuery"
        >
          <v-container>
            <v-row>
              <company-select
                @chosen="setCompany"
                v-model="companySelected"
              ></company-select>
              <date-picker
                v-if="Object.keys(companySelected).length > 0 && !chargeForm"
                @clicked="setDateRange"
                v-model="datesPicked"
              ></date-picker>
              <v-col cols="12">
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="
                    (bulkUpload = !bulkUpload),
                      (runQuery = false),
                      (companyCharges = [])
                  "
                  >Bulk Upload</v-btn
                >
                <v-btn
                  v-if="Object.keys(companySelected).length > 0"
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="showAddChargeForm()"
                  >Add New Charge</v-btn
                >
                <v-btn
                  v-if="Object.keys(companySelected).length > 0 && chargeForm"
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="(runQuery = true), (chargeForm = false)"
                  >View Charges</v-btn
                >
                <v-btn
                  v-if="Object.keys(companySelected).length > 0 && !chargeForm"
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="enableQuery"
                  color="primary"
                  @click="viewAdhocCharges()"
                  >Run Query</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-container class="ma-2" v-if="bulkUpload && this.queryData !== true">
          <v-row>
            <v-col cols="4">
              <v-file-input
                show-size
                counter
                multiple
                dense
                outlined
                prepend-icon=""
                prepend-inner-icon="attach_file"
                label="File input"
                @change="handleFileUpload"
                ref="fileInput"
              ></v-file-input>
            </v-col>
            <v-col cols="8">
              <v-btn
                class="mr-1"
                :loading="loading"
                :disabled="companyCharges.length == 0"
                color="primary"
                @click="saveBulkUpload()"
                >Save Adhoc Charges</v-btn
              >
              <v-btn
                class="ml-1 mr-1"
                :loading="loading"
                :disabled="loading"
                color="primary"
                @click="downloadTemplate()"
                >Download Template</v-btn
              >
              <v-btn
                class="ml-1"
                :loading="loading"
                :disabled="loading"
                color="primary"
                @click="
                  (bulkUpload = !bulkUpload),
                    (runQuery = true),
                    (chargeForm = false),
                    (companyCharges = [])
                "
                >Company Charges</v-btn
              >
            </v-col>
            <v-col cols="3" class="mt-0 pt-0">
              <v-checkbox
                v-model="carriersFile"
                label="Using Palletways/DHL/Evri Files"
              ></v-checkbox>
            </v-col>
            <v-col cols="4" v-if="carriersFile">
              <v-text-field
                outlined
                dense
                type="date"
                label="Charge Date"
                v-model="carriersChargeDate"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0 my-0" v-if="carriersFile">
              <p>
                All Carrier files can be uploaded at the same time, note that
                the palletways invoice files will also generate the consumable
                charges
              </p>
              <p>
                <b>- Palletways Invoice file expects columns:</b>
                'c_invoicetype', 'dc_price', 'c_carcon', 'c_cusref' and
                'i_lifts' <br />Company code will be extracted from the column
                <b>c_carcon</b> if it fails the validation it will instead try
                to extract it from <b>c_cusref</b> if it still fails the
                validation then it will be displayed as NONE
              </p>
              <p>
                <b>- DHL Distribution and/or tax file expects columns:</b> 'DHL
                Product Description', 'Shipper Reference', 'Weight',
                'Destination Code', 'Nett Charge' and 'Gross Charge'
                <br />Company Code will be extracted from column
                <b>Shipper Reference</b>
              </p>
              <p>
                <b>- EVRi Files expects columns:</b> 
                'Rates and Summary', 'Delivery Volume', '16 Digit Returns', 'Chargeable Events', '12 Digit Net', 'C2B Volume', 'SMS'. 
                Or a file with a single sheet named 'Delivery Volume' for fines.
                Rows that could not be linked to a valid order number will be ignored and will not show up on the generated table of charges to import.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <!-- card with options to include adhoc charges on bulk upload -->
      <v-card class="mt-1 pa-4" v-if="chargeForm || bulkUpload">
        <v-row>
          <v-col cols="4">
            <v-checkbox
              v-model="includeChargeToCommitted"
              @change="checkboxToggle(1)"
              label="Include Adhoc Charge on submitted billing data"
            ></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              v-model="onlyAddChargeToCommitted"
              @change="checkboxToggle(2)"
              label="Only insert Adhoc Charge on submitted billing data"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="4"
            v-if="includeChargeToCommitted || onlyAddChargeToCommitted"
          >
            <v-select
              v-model="invoiceNumber"
              :items="invoiceNumbersList"
              item-text="invoice_number"
              item-value="invoice_number"
              dense
              outlined
              menu-props="auto"
              label="Select an Invoice Number"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="chargeForm && !bulkUpload" class="mt-4 mb-4">
        <v-container>
          <v-card-title
            >Create Ad-hoc Charge for {{ companySelected.name }}</v-card-title
          >
          <v-row class="control columns">
            <div class="column">
              <charge-form
                :newCharge="adhocCharge"
                :displaySubmit="true"
                :loading="loading"
                @createCharge="createAdhocCharge()"
              ></charge-form>
            </div>
          </v-row>
        </v-container>
      </v-card>

      <!-- Table to show charges with older dates (might have already been submitted)-->
      <v-card
        v-if="oldCharges.length"
        class="my-4 px-4 py-6 red lighten-2 white--text"
      >
        <v-card-actions>
          <div>
            Found {{ oldCharges.length }} charges older than
            {{ getLastWeekStartDate().toDateString() }}, Invoice might already
            be committed
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="showOldCharges = !showOldCharges">
            <v-icon>{{
              showOldCharges ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="showOldCharges" class="mb-4">
            <v-divider></v-divider>
            <v-data-table
              :headers="chargesHeaders"
              :items="oldCharges"
              :items-per-page="5"
            ></v-data-table>
          </div>
        </v-expand-transition>
      </v-card>

      <!-- Show duplicate charges -->
      <v-card
        v-if="duplicateCharges.length"
        class="my-4 px-4 py-6 red lighten-2 white--text"
      >
        <v-card-actions>
          <div>
            Found {{ duplicateCharges.length }} potential duplicate charges,
            please check if file includes duplicates or if it was submitted
            twice
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDuplicateCharges = !showDuplicateCharges">
            <v-icon>{{
              showDuplicateCharges ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="showDuplicateCharges" class="mb-4">
            <v-divider></v-divider>
            <v-data-table
              :headers="chargesHeaders"
              :items="duplicateCharges"
              :items-per-page="5"
            ></v-data-table>
          </div>
        </v-expand-transition>
      </v-card>

      <!-- show charges already submitted -->
      <v-card
        v-if="chargesAlreadySubmitted.length"
        class="my-4 px-4 py-6 red lighten-2 white--text"
      >
        <v-card-actions>
          <div>
            Found {{ chargesAlreadySubmitted.length }}
            {{ chargesAlreadySubmitted.length === 1 ? "charge" : "charges" }}
            that might already be submitted
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="showChargesAlreadySubmitted = !showChargesAlreadySubmitted"
          >
            <v-icon>{{
              showChargesAlreadySubmitted
                ? "mdi-chevron-up"
                : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="showChargesAlreadySubmitted" class="mb-4">
            <v-divider></v-divider>
            <v-data-table
              :headers="chargesHeaders"
              :items="chargesAlreadySubmitted"
              :items-per-page="5"
            ></v-data-table>
          </div>
        </v-expand-transition>
      </v-card>

      <v-card v-if="companyCharges.length > 0" class="mt-4">
        <v-dialog v-model="dialog" max-width="75vw">
          <v-card>
            <v-card-title>
              <span class="headline">Edit {{ editedItem.shipment_id }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <charge-form
                  :newCharge="editedItem"
                  :editItemId="true"
                ></charge-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card-title>
          <div v-if="carriersFile && companyCharges.length > 0">
            <b>Successfully Loaded Files - </b> Palletways:
            {{ loadedPalletwaysFiles }} | DHL: {{ loadedDhlFiles }} | EVRi: {{loadedEvriFiles}}
          </div>
          <div v-else>
            {{ companySelected.name }} Charges between {{ datesPicked[0] }} ~
            {{ datesPicked[1] }}
          </div>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <!-- palletways table -->
        <ExportButtons
          v-if="carriersFileLoaded"
          :headers="chargesHeaders.filter((el) => el.value != 'action')"
          :data="companyCharges"
          :title="'Carrier_AhcCharges_' + carriersChargeDate"
          class="px-4"
        />
        <v-data-table
          v-if="carriersFileLoaded"
          :headers="chargesHeaders.filter((el) => el.value != 'action')"
          :items="companyCharges"
          :items-per-page="10"
          :search="search"
        >
          <template #item="{ item }">
            <tr
              :class="
                item.client === 'NONE' || item.tariff === '99999.00'
                  ? 'red lighten-3'
                  : ''
              "
            >
              <td>
                <v-text-field
                  v-model="item.client"
                  dense
                  outlined
                ></v-text-field>
              </td>
              <td>{{ item.charge_date }}</td>
              <td>{{ item.charge_type }}</td>
              <td>
                <v-text-field
                  v-model="item.reference"
                  dense
                  outlined
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.client_reference"
                  dense
                  outlined
                ></v-text-field>
              </td>
              <td>{{ item.UOM }}</td>
              <td>
                <v-text-field
                  type="number"
                  v-model="item.billable_units"
                  dense
                  outlined
                  step="1"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  type="number"
                  v-model="item.tariff"
                  dense
                  outlined
                  step="0.01"
                  prefix="£"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  type="number"
                  v-model="item.total_charge"
                  dense
                  outlined
                  step="0.01"
                  prefix="£"
                ></v-text-field>
              </td>
              <td>{{ item.charge_code }}</td>
            </tr>
          </template>
        </v-data-table>
        <!-- normal adhoc charges table -->
        <v-data-table
          v-else
          :headers="chargesHeaders"
          :items="companyCharges"
          :items-per-page="5"
          :search="search"
          id="data-table"
        >
          <template v-slot:item.total_charge="{ item }">
            <v-chip dark>{{ renderCurrency(item["total_charge"]) }}</v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <div v-if="item.id">
              <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
              <v-icon small @click="deleteItem(item)">delete</v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>

      <v-card v-if="failedCharges.length > 0" class="mt-4">
        <v-card-title>
          Charges That Failed To Upload
          <v-spacer></v-spacer>
          <div>
            <button
              @click="exportToExcel('xlsx')"
              type="button"
              class="mb-2 v-btn v-btn--depressed theme--light v-size--small primary"
            >
              Excel
            </button>
            <button
              @click="exportToExcel('csv')"
              type="button"
              class="ml-2 mb-2 v-btn v-btn--depressed theme--light v-size--small primary"
            >
              CSV
            </button>
          </div>
        </v-card-title>
        <v-data-table
          :headers="chargesHeaders"
          :items="failedCharges"
          :items-per-page="5"
          :search="search"
          id="data-table"
        >
        </v-data-table>
      </v-card>
      <v-snackbar v-model="snackbar" :timeout="6000">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false"></v-btn>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import AdhocCharges from "@/services/AdhocCharges.js";
import ChargeForm from "@/components/forms/ChargeForm";
import CompanySelect from "@/components/forms/CompanySelect";
import DatePicker from "@/components/forms/DatePicker";
import BillingSnap from "@/services/BillingSnap.js";
import moment from "moment";
import BillingCommonFunctions from "@/mixins/BillingCommonFunctions";
import ExportButtons from "@/components/ExportButtons";

import CompaniesUtil from "@/services/CompaniesUtil.js";

import ExcelMixin from "@/mixins/Excel";

import Carriers from "@/services/Carriers.js";

export default {
  mixins: [BillingCommonFunctions, ExcelMixin],
  components: {
    ChargeForm,
    CompanySelect,
    DatePicker,
    ExportButtons,
  },
  data() {
    return {
      // Title
      title: "Ad-hoc Charges",
      // Form
      valid: false,
      displaySubmit: true,
      // Snackbar
      snackbar: false,
      text: "",
      // Companies
      companySelected: {},
      datesPicked: [],
      // Charge Form
      chargeForm: false,
      // Ad-hoc Charge
      adhocCharge: {
        company_id: null,
        client: null,
        chargeDate: null,
        chargeType: null,
        reference: null,
        clientReference: null,
        UOM: null,
        billableUnits: null,
        tariff: null,
        totalCharge: null,
        chargeCode: null,
      },
      // API Response
      response: null,
      // Company Charges
      companyCharges: [],
      // Charges Table
      chargesHeaders: [
        { text: "Client", value: "client" },
        { text: "Charge Date", value: "charge_date" },
        { text: "Charge Type", value: "charge_type" },
        { text: "Reference", value: "reference" },
        { text: "Client Reference", value: "client_reference" },
        { text: "UOM", value: "UOM" },
        { text: "Billable Units", value: "billable_units" },
        { text: "Tariff", value: "tariff" },
        { text: "Total Charge", value: "total_charge" },
        { text: "Charge Code", value: "charge_code" },
        { text: "Actions", value: "action", sortable: false },
      ],
      // Search
      search: "",
      // Edit
      editedIndex: null,
      editedItem: [],
      // dialog
      dialog: false,
      // Return Message
      returnMessage: "",
      // Charge Codes
      chargeCodes: [],
      selectedChargeCode: null,
      chargeCode: null,
      newCharge: null,
      bulkUpload: false,
      queryData: false,
      runQuery: false,
      failedCharges: [],

      onlyAddChargeToCommitted: false,
      includeChargeToCommitted: false,
      invoiceNumber: "",
      showOldCharges: false,

      invoiceNumbersList: [],

      carriersFile: false,
      carriersFileLoaded: false,
      carriersChargeDate: "",
      companies: [],
      palletwaysConsumablesTarriffs: null,
      loadedPalletwaysFiles: 0,
      loadedDhlFiles: 0,
      loadedEvriFiles: 0,

      showDuplicateCharges: true,
      chargesAlreadySubmitted: [],
      showChargesAlreadySubmitted: true,
      filePassword: "rkykq5gg",
    };
  },
  watch: {
    companyCharges(val) {
      if (val && val.length) {
        this.checkIfAdhocChargesAlreadySubmitted();
      }
    },
    "adhocCharge.chargeDate": async function (val) {
      if (val) {
        this.validateAdhocChargeDate(val);
      }
    },
  },
  created() {
    this.getUncommittedInvoiceNumbers();
    this.getCompanies();
    this.getPalletwaysConsumableTariffs();
  },
  methods: {
    async validateAdhocChargeDate(date) {
      try {
        this.$root.sharedData.startLoading();
        let res = await BillingSnap.validateAdhocCharge(
          this.companySelected.id,
          date
        );

        if (res.isCommitted) {
          this.snackbar = true;
          this.text = `Cannot create adhoc charge for ${date}, invoice ${res.invoiceNumber} has already been committed`;
          this.adhocCharge.chargeDate = null;
        } else if (res.isSubmitted) {
          // if invoice is already submitted but not committed, adhoc charge needs to set includeChargeToCommitted as true
          this.snackbar = true;
          this.text = `invoice ${res.invoiceNumber} has been submitted, flagged to insert it on submitted data`;
          this.includeChargeToCommitted = true;
        } else {
          // no invoice submitted yet, so includeChargeToCommitted can be set to false
          this.includeChargeToCommitted = false;
        }
      } catch (err) {
        this.snackbar = true;
        this.text = err;
      } finally {
        this.$root.sharedData.finishLoading();
      }
    },

    async checkIfAdhocChargesAlreadySubmitted() {
      this.chargesAlreadySubmitted = [];
      let reqBody = { charges: this.companyCharges };
      this.$root.sharedData.startLoading();
      let alreadySubmitted =
        await BillingSnap.checkDuplicateCompanyBillingCharges(reqBody).catch(
          () => {
            this.$root.sharedData.finishLoading();
          }
        );
      this.$root.sharedData.finishLoading();
      if (alreadySubmitted && alreadySubmitted.length) {
        this.chargesAlreadySubmitted = alreadySubmitted;
      }
    },
    getCompanies() {
      // Use the BillingSnap to call the getBillingData() method
      CompaniesUtil.getSnapCompanies()
        .then(
          ((companies) => {
            this.$set(this, "companies", companies);

            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          return false;
        });
    },
    getUncommittedInvoiceNumbers() {
      this.$root.sharedData.startLoading();
      BillingSnap.getUncommittedInvoiceNumbers()
        .then(
          ((invoiceNumbers) => {
            this.$set(this, "invoiceNumbersList", invoiceNumbers);
            this.$root.sharedData.finishLoading();
            this.snackbar = true;
            this.text = `Successfully loaded invoice numbers`;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.message}`;
          this.$root.sharedData.finishLoading();
          return;
        });
    },
    getPalletwaysConsumableTariffs() {
      this.$root.sharedData.startLoading();
      BillingSnap.getPalletwaysConsumableTariffs()
        .then(
          ((results) => {
            this.palletwaysConsumablesTarriffs = results;
            this.$root.sharedData.finishLoading();
            this.snackbar = true;
            this.text = `Successfully loaded invoice numbers`;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.message}`;
          this.$root.sharedData.finishLoading();
          return;
        });
    },
    checkboxToggle(checkbox) {
      switch (checkbox) {
        case 1:
          if (this.includeChargeToCommitted) {
            this.onlyAddChargeToCommitted = false;
          }
          break;
        case 2:
          if (this.onlyAddChargeToCommitted) {
            this.includeChargeToCommitted = false;
          }
          break;
      }
    },
    setCompany(company) {
      this.companySelected = company;
    },
    setDateRange(dates) {
      this.datesPicked = dates;
    },
    // Currency
    renderCurrency(value) {
      if (value === null) {
        value = 0.0;
      }
      return `£${parseFloat(value).toFixed(2)}`;
    },
    // CRUD

    async createAdhocCharge() {
      if (
        (this.includeChargeToCommitted || this.onlyAddChargeToCommitted) &&
        !this.invoiceNumber
      ) {
        this.text = `invoice number required`;
        this.snackbar = true;
        return false;
      }
      this.$root.sharedData.startLoading();
      this.adhocCharge.company_id = this.companySelected.id;

      let reqBody = this.adhocCharge;
      reqBody.onlyAddChargeToCommitted = this.onlyAddChargeToCommitted;
      reqBody.includeChargeToCommitted = this.includeChargeToCommitted;
      reqBody.invoiceNumber = this.invoiceNumber;
      AdhocCharges.createCompanyCharge(reqBody)
        .then(
          ((response) => {
            this.$set(this, "response", response);

            this.$root.sharedData.finishLoading();
            this.snackbar = true;
            this.chargeForm = false;
            this.newCharge = false;
            this.text = `Charge ${this.adhocCharge.reference} created for ${this.companySelected.name}`;
            this.adhocCharge = Object.assign({}, this.defaultItem);
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.$root.sharedData.finishLoading();
          return;
        });
    },
    async viewAdhocCharges() {
      if (this.datesPicked.length !== 2) {
        this.snackbar = true;
        this.text = "Please select Date ranges from the menu.";
        return;
      }
      this.chargeForm = false;
      this.newCharge = false;
      this.$root.sharedData.startLoading();
      AdhocCharges.getAdhocCharges(
        this.companySelected.id,
        this.datesPicked[0],
        this.datesPicked[1]
      )
        .then((companyCharges) => {
          this.$set(this, "companyCharges", companyCharges);
          this.$root.sharedData.finishLoading();
          this.snackbar = true;
          if (this.companyCharges.length > 0) {
            this.text = `Displaying results for ${this.companySelected.name} between ${this.datesPicked[0]} and ${this.datesPicked[1]}`;
          } else {
            this.text = `No results found for ${this.companySelected.name} between ${this.datesPicked[0]} and ${this.datesPicked[1]}`;
          }
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.errorMessage[0]}`;
          this.$root.sharedData.finishLoading();
          return false;
        });
    },
    exportToExcel(type) {
      var wb2 = XLSX.utils.book_new();
      var ws2 = XLSX.utils.json_to_sheet(this.failedCharges);
      XLSX.utils.book_append_sheet(wb2, ws2, "Failed Charges");
      XLSX.writeFile(wb2, "FailedCharges." + type);
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    editItem(item) {
      this.editedIndex = this.companyCharges.indexOf(item);
      this.editedItem = {
        id: item.id,
        company_id: this.companySelected.id,
        client: item.client,
        chargeDate: item.charge_date,
        chargeType: item.charge_type,
        reference: item.reference,
        clientReference: item.client_reference,
        UOM: item.UOM,
        billableUnits: item.billable_units,
        tariff: item.tariff,
        totalCharge: item.total_charge,
        chargeCode: item.charge_code.toString(),
      };
      this.selectedChargeCode = this.editedItem.charge_code;
      this.dialog = true;
    },
    save() {
      this.$root.sharedData.startLoading();
      AdhocCharges.updateCharge(this.editedItem)
        .then((response) => {
          if (response.status > 202) {
            this.snackbar = true;
            this.text = `Error: ${response.errorMessage[0]}`;
            this.$root.sharedData.finishLoading();
            return false;
          } else {
            this.text = `Edited ${response.successMessage[0]} successfully.`;
            this.close();
            setTimeout(() => {
              this.snackbar = true;
              this.viewAdhocCharges();
            }, 750);
          }
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.errorMessage[0]}`;
          this.$root.sharedData.finishLoading();
          return false;
        });
    },
    async deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("API-KEY", process.env.VUE_APP_FUSION_API_KEY);
        urlencoded.append("id", item.id);
        this.$root.sharedData.startLoading();
        AdhocCharges.deleteCharge(urlencoded).then(
          ((returnMessage) => {
            this.snackbar = true;
            this.text = `Deleted ${item.reference} Services`;
            this.$set(this, "returnMessage", returnMessage);
            this.$root.sharedData.finishLoading();
          }).bind(this)
        );
        setTimeout(() => {
          this.viewAdhocCharges();
        }, 1000);
      }
    },
    // Show New Charge Form
    showAddChargeForm() {
      this.chargeForm = true;
      this.newCharge = true;
      this.companyCharges = [];
      this.adhocCharge.client = this.companySelected.code;
    },
    resetBulkChargesForm() {
      this.companyCharges = [];
      this.carriersFileLoaded = false;
      this.chargesAlreadySubmitted = [];
    },
    async handleFileUpload(ev) {
      this.resetBulkChargesForm();

      const file = ev[0];
      if (file && !this.carriersFile) {
        this.extractJson(file)
          .then((result) => {
            this.companyCharges = result;
          })
          .catch((err) => {
            this.snackbar = true;
            this.text = err;
          });
      } else if (file && this.carriersFile) {
        if (!this.carriersChargeDate) {
          this.snackbar = true;
          this.text = "please select a charge date and try again";
          this.$refs.fileInput.$refs.input.value = null;
          this.$refs.fileInput.reset();
          return;
        }
        try {
          this.loadedPalletwaysFiles = 0;
          this.loadedDhlFiles = 0;
          this.loadedEvriFiles = 0;
          let combinedFilesCharges = [];
          // check for duplicate files
          this.checkForDuplicateFiles(ev);
          // run process for each file
          for (let carriersFile of ev) {
            let jsonFromFile = await this.extractCarriersJson(carriersFile);
            combinedFilesCharges = combinedFilesCharges.concat(jsonFromFile);
          }
          this.carriersFileLoaded = true;
          this.companyCharges = this.sortChargesData(combinedFilesCharges);
        } catch (err) {
          this.snackbar = true;
          this.text = err.response ? err.response.data.message : err;
          this.$root.sharedData.finishLoading();
        }
      }
    },
    checkForDuplicateFiles(files) {
      let msg = "";
      for (let file of files) {
        let similar = files.filter((el) => el.size === file.size);
        if (similar.length > 1)
          msg += `${file.name} might be a duplicate file | `;
      }
      if (msg) {
        this.text = msg;
        this.snackbar = true;
      }
    },
    extractJson(file) {
      var fileReader = new FileReader();
      return new Promise((resolve, reject) => {
        fileReader.onload = function (e) {
          // pre-process data
          var binary = "";
          var bytes = new Uint8Array(e.target.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          // call 'xlsx' to read the file
          var oFile = XLSX.read(binary, {
            type: "binary",
            cellDates: true,
            cellStyles: true,
          });
          var sheetName = oFile.SheetNames[0];
          var adhocCharges = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName]);
          let tempArray = [];
          try {
            adhocCharges.forEach((charge) => {
              let date = new Date(charge["Charge Date"]);
              if (date == "Invalid Date") {
                throw Error(
                  "Date is not formatted correctly. Please try format the Charge Date column as Long Date in Excel and re-upload."
                );
              }
              if (!charge.Client) {
                let rowNumber = charge.__rowNum__ ? charge.__rowNum__ + 1 : "";
                throw Error(` Client missing in row ${rowNumber}`);
              }

              let dateString = moment(date).format("YYYY-MM-DD");
              // setup function to round number fields
              function round(value, exp) {
                if (typeof exp === "undefined" || +exp === 0)
                  return Math.round(value);

                value = +value;
                exp = +exp;

                if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
                  return "";

                // Shift
                value = value.toString().split("e");
                value = Math.round(
                  +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
                );

                // Shift back
                value = value.toString().split("e");
                return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
              }

              var tempChargeUpload = {
                client: charge.Client.trim(),
                charge_date: dateString,
                charge_type: charge["Charge Type"],
                reference: charge["Reference (Order Number)"]
                  ? String(charge["Reference (Order Number)"]).toUpperCase()
                  : "",
                client_reference: charge["Client Reference"]
                  ? String(charge["Client Reference"]).toUpperCase()
                  : "",
                UOM: charge.UOM,
                billable_units: charge["Billable Units"],
                tariff: round(charge.Tariff, 2),
                total_charge: round(charge["Total Charge"], 2),
                charge_code: charge["Charge Code"],
              };
              tempArray.push(tempChargeUpload);
            });
          } catch (error) {
            reject(error);
          }
          if (!tempArray) reject("error");
          resolve(tempArray);
        };
        fileReader.readAsArrayBuffer(file);
      });
    },

    async parseBinaryFile(binaryStr) {
      try {
        let oFile = XLSX.read(binaryStr, {
          type: "binary",
          cellFormula: true,
          cellDates: true,
          sheetStubs: true,
        });
        return oFile;
      } catch (err) {
        if (err.message === "File is password-protected") {
          const decrypted = await this.decryptPassword(
            binaryStr,
            this.filePassword
          );
          let oFile = XLSX.read(decrypted, {
            type: "buffer",
            cellFormula: true,
            cellDates: true,
            sheetStubs: true,
          });
          return oFile;
        } else {
          throw err;
        }
      }
    },

    async extractCarriersJson(file) {
      this.$root.sharedData.startLoading();
      let binary = await this.readFileAsBinary(file);

      // call 'xlsx' to read the file
      var oFile = await this.parseBinaryFile(binary);
      var sheetNames = oFile.SheetNames;
      var sheetName = oFile.SheetNames[0];
      var palletwayCharges = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName]);
      let tempArray = [];

      const evriSheets = [
        "Rates and Summary",
        "Delivery Volume",
        "16 Digit Returns",
        "Chargeable Events",
        "12 Digit Net",
        "C2B Volume",
        "SMS",
      ];

      // theres 2 different types of files, invoice and consumables
      if (palletwayCharges[0]["c_invoicetype"]) {
        tempArray = this.formatPalletwaysInvoiceData(palletwayCharges);
        // also generate consumable charges from invoice data
        tempArray = tempArray.concat(
          this.generateConsumablesDataFromInvoice(palletwayCharges)
        );
        this.loadedPalletwaysFiles++;
        return tempArray;
      } else if (palletwayCharges[0]["DHL Product Description"]) {
        tempArray = await this.generateDhlInvoiceCharges(palletwayCharges);

        this.loadedDhlFiles++;
        return tempArray;
      } else if (evriSheets.every((item) => sheetNames.includes(item))) {
        tempArray = await this.generateEvriInvoiceCharges(oFile, false);
        this.loadedEvriFiles++;
      } else if (sheetNames.length < 3 && sheetNames[0] === "Delivery Volume") {
        tempArray = await this.generateEvriInvoiceCharges(oFile, true);
        this.loadedEvriFiles++;
      } else {
        // old process to get the palletways consumables from a different file
        // tempArray =
        //   self.formatPalletwaysConsumablesData(palletwayCharges);
        throw new Error(
          "unexpected file encountered,column 'c_invoicetype' OR 'DHL Product Description' not found, no charges have been generated for sheet " +
            sheetName
        );
      }
  
      this.$root.sharedData.finishLoading();
      return tempArray;
    },
    async generateEvriInvoiceCharges(parsedFile, devVolFile = false) {
      let generatedCharges = [];
      const sheetsToRead = devVolFile ? ["Delivery Volume"] : ["16 Digit Returns", "Chargeable Events", "SMS"];
      for (const sheetName of sheetsToRead) {
        const sheetArr = XLSX.utils.sheet_to_json(
          parsedFile.Sheets[sheetName],
          {
            header: 1,
            raw: true,
          }
        );
        // remove header
        const rows = sheetArr.slice(1, sheetArr.length);
    
        for (const row of rows) {
          let tempChargeUpload = null;
          switch (sheetName) {
            case "Delivery Volume":
              tempChargeUpload = this.evriDelVolCharge(row);
              break;
            case "16 Digit Returns":
              tempChargeUpload = this.evriReturnCharge(row);
              break;
            case "Chargeable Events":
              tempChargeUpload = this.evriEventCharge(row);
              break;
            case "SMS":
              tempChargeUpload = this.evriSmsCharge(row);
              break;
          }
          generatedCharges.push(tempChargeUpload);
        }
      }

      const validatedData = await this.matchInvoiceDataOrderNumber(generatedCharges);
      const mappedData = await this.findChargesOrderNumber(validatedData);
      const withCompany = await this.setCompanyEvriCharges(mappedData)

      return withCompany;
    },
    async setCompanyEvriCharges(charges){
      let filtered = []
      for(const charge of charges){
        if(!charge.reference) continue 
        let companyCode = String(charge.reference).substring(0, 3)
         companyCode = this.validateCompanyCode(companyCode);
         charge.client = companyCode

          if (charge.hasOwnProperty('carrier_ref_1')) {
            delete charge.carrier_ref_1;
          }
          if (charge.hasOwnProperty('carrier_ref_2')) {
            delete charge.carrier_ref_2;
          }
          if (charge.hasOwnProperty('customer_ref_1')) {
            delete charge.customer_ref_1;
          }
          if (charge.hasOwnProperty('customer_ref_2')) {
            delete charge.customer_ref_2;
          }
         
         filtered.push(charge)
      }
      return filtered
    },
    evriDelVolCharge(row) {
      let undercharge = parseFloat(row[25]) ? parseFloat(row[25]) : 0
      let charge = parseFloat(row[28]) ? parseFloat(row[28])  : 0
      const margin = 1.2
      let totalCharge = this.round( (undercharge + charge) * margin, 2)
      let tempChargeUpload = {
        client: "",
        charge_date: this.carriersChargeDate,
        charge_type: "EVRi",
        reference: "",
        client_reference: `${row[21]} - ${row[26]}`,
        UOM: "Per Charge",
        billable_units: 1,
        tariff: totalCharge,
        total_charge: totalCharge,
        charge_code: 4019,
        carrier_ref_1: row[4],
        carrier_ref_2: '',
        customer_ref_1: row[8], 
        customer_ref_2: row[9],
      };
      return tempChargeUpload;
    },
    evriSmsCharge(row) {
      const margin = 1.2
      let totalCharge = this.round( row[11] * margin, 2)
      let tempChargeUpload = {
        client: "",
        charge_date: this.carriersChargeDate,
        charge_type: "EVRi",
        reference: "",
        client_reference: row[10],
        UOM: "Per Charge",
        billable_units: 1,
        tariff: totalCharge,
        total_charge: totalCharge,
        charge_code: 4019,
        carrier_ref_1: row[3],
        carrier_ref_2: "",
        customer_ref_1: row[7],
        customer_ref_2: row[8],
      };
      return tempChargeUpload;
    },
    evriEventCharge(row) {
      const margin = 1.2
      let totalCharge = this.round( row[16] * margin, 2)
      let tempChargeUpload = {
        client: "",
        charge_date: this.carriersChargeDate,
        charge_type: "EVRi",
        reference: "",
        client_reference: `${row[15]} - ${row[13]}`,
        UOM: "Per Charge",
        billable_units: 1,
        tariff: totalCharge,
        total_charge: totalCharge,
        charge_code: 4019,
        carrier_ref_1: row[4],
        carrier_ref_2: row[5],
        customer_ref_1: row[9],
        customer_ref_2: row[10],
      };
      return tempChargeUpload;
    },
    evriReturnCharge(row) {
      const margin = 1.2
      let totalCharge = this.round( row[13] * margin, 2)
      let tempChargeUpload = {
        client: "",
        charge_date: this.carriersChargeDate,
        charge_type: "EVRi",
        reference: "",
        client_reference: row[12],
        UOM: "Per Charge",
        billable_units: 1,
        tariff: totalCharge,
        total_charge: totalCharge,
        charge_code: 4019,
        carrier_ref_1: row[4],
        carrier_ref_2: "",
        customer_ref_1: "",
        customer_ref_2: "",
      };
      return tempChargeUpload;
    },
    async matchInvoiceDataOrderNumber(data) {
      for (const row of data) {
        if (row.customer_ref_1) {
          row.reference =
            typeof row.customer_ref_1 === "string"
              ? row.customer_ref_1.replace(/\s/g, "")
              : row.customer_ref_1;
        } else {
          row.reference = "";
        }
      }
      // validate order numbers
      let validated = await this.validateChargesOrderNumbers(data);
      return validated;
    },

    async validateChargesOrderNumbers(charges) {
      const orderNumbers = charges.map((el) => el.reference);

      const results = await this.validateOrderNumbers(orderNumbers);

      // cant validate
      if (!results) return charges;

      const invalidOrderNumbers = results.invalidOrderNumbers;

      // clear order_number field if reference is in invalidOrderNumbers array
      for (const charge of charges) {
        if (invalidOrderNumbers.includes(charge.order_number)) {
          charge.reference = "";
        }
      }

      return charges;
    },
    async validateOrderNumbers(orderNumbers) {
      try {
        let reqBody = {
          orderNumbers: orderNumbers,
        };
        let response = await Carriers.validateOrderNumbers(reqBody);
        return response.data;
      } catch (error) {
        const text = error.response
          ? error.response.data.message
          : `${error.message}`;
        this.activateSnackbar(text);
        return null;
      }
    },
      async findChargesOrderNumber(charges) {
      let referencesToMap = [];
      for (const charge of charges) {
        // skip charges which already have order_number populated
        if (charge.reference) continue;
        if (charge.carrier_ref_1) {
          referencesToMap.push(charge.carrier_ref_1);
        }
        if (charge.carrier_ref_2) {
          referencesToMap.push(charge.carrier_ref_2);
        }
        if (charge.customer_ref_1) {
          referencesToMap.push(charge.customer_ref_1);
        }
        if (charge.customer_ref_2) {
          referencesToMap.push(charge.customer_ref_2);
        }
        
      }
      if (referencesToMap.length) {
        let mappedReferences = await this.getOrderNumbersFromReferences(
          referencesToMap
        );
        if (mappedReferences) {
          charges = this.mapReferencesToCharges(charges, mappedReferences);
        }
      }

      return charges;
    },

    async getOrderNumbersFromReferences(references) {
      try {
        let reqBody = {
          references: references,
        };
        let response = await Carriers.findOrderNumbersFromReferences(reqBody);
        return response.data;
      } catch (error) {
        const text = error.response
          ? error.response.data.message
          : `${error.message}`;
        this.activateSnackbar(text);
        return null;
      }
    },
    mapReferencesToCharges(charges, references) {
      for (const charge of charges) {
        if (charge.reference) continue;
        const matched = references.find((orderNumber) => {
          return (
            charge.carrier_ref_1 === orderNumber.reference ||
            charge.carrier_ref_2 === orderNumber.reference ||
            charge.customer_ref_1 === orderNumber.reference ||
            charge.customer_ref_2 === orderNumber.reference
          );
        });
        if (matched) {
          charge.reference = matched.order_number;
        }
      }
      return charges;
    },

    activateSnackbar(text) {
      this.text = text;
      this.snackbar = true;
    },

    sortChargesData(charges) {
      // sort charges data so client NONE show up first (invalid company code, NONE is 4 characters long whereas a valid company code is 3)
      let sorted = charges.sort(
        (a, b) => parseFloat(b.tariff) - parseFloat(a.tariff)
      );
      return sorted.sort((a, b) => b.client.length - a.client.length);
    },
    groupDhlData(charges) {
      let groupedCharges = this.groupBy(charges, "Shipper Reference");
      let tempCharges = [];
      for (const objKey of Object.keys(groupedCharges)) {
        let keyCharges = groupedCharges[objKey];
        // clone first obj in array
        let tempObj = { ...keyCharges[0] };
        // sum columns
        tempObj["Weight"] = this.sumKey(keyCharges, "Weight");
        tempObj["Gross Charge"] = this.sumKey(keyCharges, "Gross Charge");
        tempObj["Nett Charge"] = this.sumKey(keyCharges, "Nett Charge");
        const potentialServices = keyCharges.map(item => item["DHL Product Description"]);
        tempObj["DHL Product Description"] = potentialServices
        tempCharges.push(tempObj);
      }
      return tempCharges;
    },
    sumKey(array, key) {
      return array.reduce((sum, current) => {
        return sum + current[key];
      }, 0);
    },
    getContryFromDhlFile(charge){
      if(charge['Receiver Details']){
        const str = charge['Receiver Details']
        const parts = str.split(';').map(s => s.trim()); // Split and trim
        const countryCode = parts.slice(-3, -1)[0]; // Get the second-to-last element
        if(!countryCode || countryCode.length !== 2){
          // throw new Error('Could not extract country code for ' + charge['Shipper Reference'])
          // the API will still be able to retrieve the country code from the order details, so we can skip erroring,
          // and we only use this country mapping as a failover
          return ''
        }
        return countryCode
    
      }else{
        return ''
      }
    },
    async getChargeWithMargin(companyCode, orderNumber, 
    carrier, service, date, 
    totalCost, numPackages, country,
    postCode, weight){
      const reqBody = {
        companyCode: companyCode,
        isDhl: true,
        charges: [{
          company_code: companyCode,
            orderReference:orderNumber,
            carrier_name: carrier,
            service: service,
            dateShipped: date,
            totalCost: totalCost,
            numberOfPackages: numPackages,
            country: country,
            postCode: postCode,
            weight: weight
        }]
      }
      let charge = await Carriers.applyMarginsToCharges(reqBody)
      if(!charge || charge.length < 1) throw new Error('error getting charge with margin')

      return charge.data[0]
    },
    async generateDhlInvoiceCharges(charges) {
      let tempArray = [];
      const carrierRef = 'DHL'
      let chargeType = carrierRef
      let isDhlDutyAndTaxesFile = this.checkDhlDutyAndTaxesFile(charges[0]);
      if (!isDhlDutyAndTaxesFile) {
        charges = this.groupDhlData(charges);
      }
      for (const row of charges) {
        let companyCode = row["Shipper Reference"]
          ? String(row["Shipper Reference"]).substring(0, 3)
          : "NONE";
        companyCode = this.validateCompanyCode(companyCode);

        let billableUnits = 1;

        let margin = 1.175
        let tariff = row["Gross Charge"] * margin;
        let totalCharge = tariff * billableUnits;

        if(isDhlDutyAndTaxesFile){
          if(row["DHL Product Description"] !== "DUTY TAX PAID" ){
            margin = 1
            tariff = row["Gross Charge"] * margin;
            totalCharge = tariff * billableUnits;
          }
        }else{
          if(companyCode !== 'NONE'){
              // get margins from company margins here 
            const country = this.getContryFromDhlFile(row)
            const calculatedCharge = await this.getChargeWithMargin(companyCode,row["Shipper Reference"], 
            carrierRef, row["DHL Product Description"], 
            this.carriersChargeDate,row["Gross Charge"], billableUnits,country,"", row["Weight"] )
            
            margin = 1 + ((calculatedCharge.applied_margin) / 100) //not need but will leave it in case we need to debug
            tariff = calculatedCharge.tariff
            totalCharge = calculatedCharge.totalCharge
            chargeType = calculatedCharge.chargeType
          }
        }
          
      

        if (totalCharge == 0) continue;

        let tempChargeUpload = {
          client: companyCode,
          charge_date: this.carriersChargeDate,
          charge_type: chargeType,
          reference: row["Shipper Reference"],
          client_reference: isDhlDutyAndTaxesFile
            ? row["DHL Product Description"]
            : row["Destination Code"],
          UOM: isDhlDutyAndTaxesFile ? "" : row["Weight"] + "Kg",
          billable_units: billableUnits,
          tariff: this.round(tariff, 2),
          total_charge: this.round(totalCharge, 2),
          charge_code: isDhlDutyAndTaxesFile ? 4016 : 4010,
        };

        tempArray.push(tempChargeUpload);
      }
      return tempArray;
    },
    checkDhlDutyAndTaxesFile(row) {
      const dutyAndTaxesReferences = [
        "DUTIES & TAXES",
        "DUTY TAX PAID",
        "IMPORT EXPORT TAXES",
        "IMPORT EXPORT DUTIES",
      ];
      return dutyAndTaxesReferences.includes(row["DHL Product Description"]);
    },
    parsePalletwaysUnits(str) {
      // extra numbers from string (they will be in an array format) for example: 'HP-11,QP-3' will yield ['11', '3']
      let numbersArray = str.match(/[\d.]+/g);
      // sum all numbers in array
      let units = numbersArray.reduce(
        (partialSum, a) => parseInt(partialSum) + parseInt(a),
        0
      );
      return units;
    },
    getCompanyPalletwaysConsumableTariff(type, companyCode) {
      let tariffMatrix = this.palletwaysConsumablesTarriffs;
      let foundObj = tariffMatrix ? tariffMatrix[type][companyCode] : null;
      if (foundObj) {
        return foundObj;
      } else {
        return type === "Wood/Wrap"
          ? { chargeCode: 4007, tariff: 99999 }
          : { chargeCode: 4005, tariff: 99999 };
      }
    },
    formatPalletwaysConsumablesData(palletwayCharges) {
      let tempArray = [];
      for (const row of palletwayCharges) {
        const chargeTypes = ["Loading Out", "Strapping", "Wood/Wrap"];
        for (const chargeType of chargeTypes) {
          let companyCode = row["Consignment Number"]
            ? row["Consignment Number"].substring(0, 3)
            : "NONE";
          companyCode = companyCode.toUpperCase();
          if (companyCode !== "COR" && chargeType === "Strapping") continue;

          let typeTariff = this.getCompanyPalletwaysConsumableTariff(
            chargeType,
            companyCode
          );

          let ref = companyCode + "-" + chargeType;

          // calculate units, tariff and totalCharge
          let billUnits = row["Billing Units"];
          let parsedUnits = this.parsePalletwaysUnits(billUnits);
          let totalCharge = typeTariff.tariff * parsedUnits;

          let tempChargeUpload = {
            client: companyCode,
            charge_date: this.carriersChargeDate,
            charge_type: chargeType,
            reference: ref,
            client_reference: row["Consignment Number"]
              ? row["Consignment Number"]
              : row["Customer Ref"],
            UOM: "Per Pallet",
            billable_units: parsedUnits,
            tariff: this.round(typeTariff.tariff, 2),
            total_charge: this.round(totalCharge, 2),
            charge_code: typeTariff.chargeCode,
          };
          tempArray.push(tempChargeUpload);
        }
      }
      return tempArray;
    },
    extractCompanyCodeFromPalletwaysInvoice(row) {
      let companyCode = "NONE";
      companyCode = row["c_carcon"]
        ? (row["c_carcon"] + "").substring(0, 3)
        : "NONE";
      companyCode = this.validateCompanyCode(companyCode);

      //for invalid company codes retry using different column
      if (companyCode === "NONE") {
        companyCode = row["c_cusref"]
          ? this.validateCompanyCode((row["c_cusref"] + "").substring(0, 3))
          : "NONE";
      }
      if (companyCode === "NONE") {
        this.text =
          "Found invalid company code reference, please check the table for invalid rows";
        this.snackbar = true;
      }
      return companyCode;
    },
    generateConsumablesDataFromInvoice(palletwayCharges) {
      let tempArray = [];
      const alreadyApplied = {};
      for (let i = 0; i < palletwayCharges.length - 1; i++) {
        const row = palletwayCharges[i];
        if(row['notecomment']?.includes("Amazon surcharge") ) continue
        const chargeTypes = ["Loading Out", "Strapping", "Wood/Wrap"];
        for (const chargeType of chargeTypes) {
          let clientRef = row["c_carcon"] ? row["c_carcon"] : row["c_cusref"];
          // validate company code
          //(row +'' ) to coerse columns where it only has numbers to be converted to string
          let companyCode = this.extractCompanyCodeFromPalletwaysInvoice(row);

          if (companyCode !== "COR" && chargeType === "Strapping") continue;

          let typeTariff = this.getCompanyPalletwaysConsumableTariff(
            chargeType,
            companyCode
          );

          let ref = companyCode + "-" + chargeType;

          // calculate units, tariff and totalCharge
          let parsedUnits = row["i_lifts"];

          let totalCharge = typeTariff.tariff * parsedUnits;

          // for multiple rows of the same reference on the invoice we need to make sure they are not applied consumables twice
          alreadyApplied[chargeType] = alreadyApplied[chargeType] || {};
          if (alreadyApplied[chargeType][clientRef]) {
            // skip if charge has already been applied for this ref+units
            if (alreadyApplied[chargeType][clientRef][parsedUnits]) continue;
          } else {
            alreadyApplied[chargeType][clientRef] = {};
          }
          alreadyApplied[chargeType][clientRef][parsedUnits] = true;

          let tempChargeUpload = {
            client: companyCode,
            charge_date: this.carriersChargeDate,
            charge_type: chargeType,
            reference: ref,
            client_reference: clientRef,
            UOM: "Per Pallet",
            billable_units: parsedUnits,
            tariff: this.round(typeTariff.tariff, 2),
            total_charge: this.round(totalCharge, 2),
            charge_code: typeTariff.chargeCode,
          };
          tempArray.push(tempChargeUpload);
        }
      }
      return tempArray;
    },
    formatPalletwaysInvoiceData(palletwayCharges) {
      let tempArray = [];
      let fuelSurchargeRow = palletwayCharges[palletwayCharges.length - 1];
      let fuelSurchargeString = fuelSurchargeRow["description"] ?? "";
      let extractNumber = fuelSurchargeString.match(/[\d.]+/g);
      let fuelSurcharge = parseFloat(extractNumber ? extractNumber : 0) / 100;
      // skip last row as that one is for the fuel surcharge
      for (let i = 0; i < palletwayCharges.length - 1; i++) {
        let row = palletwayCharges[i];
        // let dateString = moment(row["d_duedate"]).format("YYYY-MM-DD");
        let totalCharge =
          (row["dc_price"] + row["dc_price"] * fuelSurcharge) * 1.2;
        let companyCode = this.extractCompanyCodeFromPalletwaysInvoice(row);

        let tempChargeUpload = {
          client: companyCode,
          charge_date: this.carriersChargeDate,
          charge_type: "Pallet Distribution",
          reference: row["c_carcon"],
          client_reference: row["c_cusref"],
          UOM: "",
          billable_units: row["i_lifts"],
          tariff: this.round(totalCharge, 2),
          total_charge: this.round(totalCharge, 2),
          charge_code: "4011",
        };

        tempArray.push(tempChargeUpload);
      }
      return tempArray;
    },
    validateCompanyCode(companyCode) {
      if (!companyCode) return "NONE";
      companyCode = companyCode.toUpperCase();
      let foundCompanyCode = this.companies.find(
        (el) => el.code === companyCode
      );

      if (foundCompanyCode) {
        return companyCode;
      } else {
        return "NONE";
      }
    },
    async saveBulkUpload() {
      if (
        (this.includeChargeToCommitted || this.onlyAddChargeToCommitted) &&
        !this.invoiceNumber
      ) {
        this.text = `invoice number required`;
        this.snackbar = true;
        return false;
      }
      this.failedCharges = [];
      this.$root.sharedData.startLoading();
      let jsonArray = [];
      this.companyCharges.forEach((charge) => {
        let companyTemp = this.$root.sharedData.state.companies.find(
          (company) => company.code === charge.client
        );
        if (!companyTemp) {
          this.failedCharges.push(charge);
          this.$root.sharedData.finishLoading();
          this.text = `Company ${charge.client} not recognised - Please check the failed charges table below and edit the details in the Excel file.`;
          this.snackbar = true;
          return false;
        }
        charge.company_id = companyTemp.id;
        jsonArray.push(charge);
      });

      let reqBody = {
        onlyAddChargeToCommitted: this.onlyAddChargeToCommitted,
        includeChargeToCommitted: this.includeChargeToCommitted,
        invoiceNumber: this.invoiceNumber,
        charges: jsonArray,
      };
      AdhocCharges.bulkUploadCharges(reqBody)
        .then((response) => {
          this.text = response;
          this.snackbar = true;
          this.companyCharges = [];
          this.$root.sharedData.finishLoading();
        })
        .catch((error) => {
          this.$root.sharedData.finishLoading();
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.snackbar = true;
        });
    },
    round(value, decimalPlaces) {
      if (typeof decimalPlaces === "undefined" || +decimalPlaces === 0)
        return Math.round(value);

      if (isNaN(value)) return "";

      return Number(
        Math.round(parseFloat(value + "e" + decimalPlaces)) +
          "e-" +
          decimalPlaces
      ).toFixed(decimalPlaces);
    },
    downloadTemplate() {
      let tempArray = [];
      let tempItem = {
        Client: "3PL",
        "Charge Date": new Date(),
        "Charge Type": "Consumables",
        "Reference (Order Number)": "3PLEXREF",
        "Client Reference": "CLIREF",
        UOM: 10,
        "Billable Units": 100,
        Tariff: 1,
        "Total Charge": 100,
        "Charge Code": 4005,
      };
      tempArray.push(tempItem);
      var elt = tempArray;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(elt);
      XLSX.utils.book_append_sheet(wb, ws, "Adhoc Bulk Upload");
      XLSX.writeFile(wb, "Adhoc Bulk Upload.csv");
    },
  },
  computed: {
    duplicateCharges() {
      let duplicates = [];
      let companyCharges = this.companyCharges;
      if (companyCharges && companyCharges.length) {
        duplicates = companyCharges.filter(
          (obj, index) =>
            companyCharges.findIndex(
              (item) =>
                item.UOM === obj.UOM &&
                item.billable_units === obj.billable_units &&
                item.charge_code === obj.charge_code &&
                item.charge_date === obj.charge_date &&
                item.charge_type === obj.charge_type &&
                item.client === obj.client &&
                item.client_reference === obj.client_reference &&
                item.reference === obj.reference &&
                item.tariff === obj.tariff &&
                item.total_charge === obj.total_charge
            ) !== index && obj.total_charge != 0.03
        );
      }
      return duplicates;
    },

    loading: function () {
      return this.$root.sharedData.state.loading;
    },
    oldCharges() {
      let oldChargesArr = [];
      if (!this.companyCharges || this.companyCharges.length < 1) {
        return oldChargesArr;
      }
      let lastWeekStartDate = this.getLastWeekStartDate();
      for (let row of this.companyCharges) {
        let chargeDate = new Date(row.charge_date);
        chargeDate.setHours(0);
        chargeDate.setMinutes(0);
        chargeDate.setSeconds(0);
        if (chargeDate < lastWeekStartDate) {
          oldChargesArr.push(row);
        }
      }
      return oldChargesArr;
    },
    enableQuery: {
      // getter
      get: function () {
        if (
          this.companySelected.id &&
          this.datesPicked[0] &&
          this.datesPicked[1]
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.errorMessage p {
  color: red;
}
</style>